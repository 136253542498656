var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"warning","to":"/rolelist"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("folder_shared")])],1)]}}])},[_c('span',[_vm._v("Uloge")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"info","to":"/registrationlist"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("On-line prijave novih članova")])]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"accent","to":{ path: '/useredit/-1' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"userList",staticClass:"lg-table",attrs:{"headers":[
              { text: 'ID', value: 'id' },
              { text: 'Korisničko ime', value: 'user_name' },
              { text: 'Ime', value: 'first_name' },
              { text: 'Prezime', value: 'last_name' },
              { text: 'E-mail', value: 'email' },
              { text: 'Članski broj', value: 'membership_regnum' }
            ],"apiLoad":"user/users.php","actions":[
              {
                id: '1',
                name: 'Uredi korisnika',
                path: '/useredit/#id#',
                icon: 'edit',
                color: 'secondary'
              }
            ],"defaultFilter":"last_name","defaultSort":"last_name"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }