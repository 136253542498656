<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            fab
            dark
            class="mx-1"
            color="warning"
            v-on="on"
            to="/rolelist"
          >
            <v-icon dark>folder_shared</v-icon>
          </v-btn>
        </template>
        <span>Uloge</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            fab
            dark
            class="mx-1"
            color="info"
            v-on="on"
            to="/registrationlist"
          >
            <v-icon dark>person_add</v-icon>
          </v-btn>
        </template>
        <span>On-line prijave novih članova</span>
      </v-tooltip>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
      <v-btn
        small
        fab
        dark
        class="mx-1"
        color="accent"
        :to="{ path: '/useredit/-1' }"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="userList"
              class="lg-table"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Korisničko ime', value: 'user_name' },
                { text: 'Ime', value: 'first_name' },
                { text: 'Prezime', value: 'last_name' },
                { text: 'E-mail', value: 'email' },
                { text: 'Članski broj', value: 'membership_regnum' }
              ]"
              apiLoad="user/users.php"
              :actions="[
                {
                  id: '1',
                  name: 'Uredi korisnika',
                  path: '/useredit/#id#',
                  icon: 'edit',
                  color: 'secondary'
                }
              ]"
              defaultFilter="last_name"
              defaultSort="last_name"
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "UserList",
  data() {
    return {};
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.userListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.userList.reload();
      this.$store.dispatch("user_list_refreshed");
    }
  }
};
</script>
